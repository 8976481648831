// SearchContext.js
import React, { createContext, useState, FC, ReactNode } from 'react';

import { IFacilityInterface, IContextInterface } from '../../@types/context';

import ConstantService from '../../services/constant.service';

function delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}

// nop versions of setting the context
function default0(session: string) {};
function default1(search?: string, searchState?: string) {};
function default2(matches: IFacilityInterface[], error?: string) {};
function default3(listview: boolean, mapview: boolean, welcome: boolean) {};

const sv = ConstantService.getStateView();
const defaultSearchContext = {
  sessionid: '',
  search: '',
  searchState: sv ? sv : '',
  searchCity: '',
  saveSession: default0,
  saveSearch: default1,
};


interface Props {
  children: ReactNode;
}

export const SearchContext = createContext<IContextInterface>(defaultSearchContext);

//const AppProvider: React.FC<React.ReactNode> = ({ children: {children: ReactNode} }) => {
const AppSearchProvider: React.FC<Props> = ({ children }: Props) => {
  const [context, setContext] = React.useState<IContextInterface>();

  //React.useEffect(() => {
  //  //console.log('useEffect context', context);
  //})

  const saveSearch = async (newsearch?: string, newsearchState?: string) => {
    //console.log('saveSearch', newsearch, newsearchState);
    // console.log('saveSearch', search, context);
    // console.log('saveSearch', search, searchState);
    const newcontext: IContextInterface = {
      sessionid: context ? context.sessionid : '',
      search: (newsearch == null) ? search : newsearch,
      searchCity: '',
      searchState: (newsearchState == null) ? searchState : newsearchState,
      saveSession: context ? context.saveSession: default0,
      saveSearch: context ? context.saveSearch : default1,
    };

    
    setContext(newcontext);
    //await delay(10);
    //console.log('new context', newcontext, context);
  }

  const saveSession = (sessionid: string) => {
    //console.log(`saveSession: ${context?.searchState}`);
    const newcontext: IContextInterface = {
      sessionid,
      search: context ? context.search : '',
      searchCity: context ? context.searchCity : '',
      searchState: context ? context.searchState : '',
      saveSession: context ? context.saveSession: default0,
      saveSearch: context ? context.saveSearch : default1,
    };
    setContext(newcontext);
  }

  const sessionid = context ? context.sessionid : '';
  const search = context ? context.search : '';
  const searchCity = context ? context.searchCity : '';
  const sv = ConstantService.getStateView();
  const searchState = sv ? sv : (context ? context.searchState : '');

  return <SearchContext.Provider value={{ sessionid, search, searchCity, searchState, saveSession, saveSearch }}>{children}</SearchContext.Provider>;
};  
  
export default AppSearchProvider;
