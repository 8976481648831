// Mapview.tsx

import * as React from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import { LatLngBoundsLiteral, LatLngBounds } from 'leaflet';
import { Link } from 'react-router-dom';

import { IContextInterface, IFileInterface, IMatchInterface, IFacilityInterface } from '../../@types/context';
import { SearchContext } from '../context/SearchContext';
import { MatchContext } from '../context/MatchContext';

import '../../styles.css'
import { match } from 'assert';

interface MapSettings {
  matchesWithCoords: IFacilityInterface[];
  lat: number;
  lng: number;
  latsum: number; // Sum of lat values to detect changes
  bounds: any;
};


let sequenceid: number = 1;

const Mapview: React.FC = () => {
  const { sessionid } = React.useContext(SearchContext) as IContextInterface;
  const { matches, stateview, mapview } = React.useContext(MatchContext) as IMatchInterface;

  const defaultlat = 37;
  const defaultlng = -95.8;
  const emptySettings = {
    matchesWithCoords: [],
    lat: defaultlat,
    lng: defaultlng,
    latsum: 0,
    bounds: [[24.5,-124.7], [49.4, -66.9]]
  }
  const [mapSettings, setMapSettings] = React.useState<MapSettings>(emptySettings);

  //let matchesWithCoords: IFacilityInterface[] = [];
  //  = matches.filter(x => x.lat && x.lat.length > 0 && x.long && x.long.length > 0);
  //let lat = 28.002;
  //let lng = -82.57;
  //let bounds = [[lat, lng] as any, [lat, lng] as any];

  // Effect that runs when matches or settings change
  React.useEffect(() => {
    // console.log('Mapview useEffect. matches has changed', matches.length);

    const matchesWithCoords = matches.filter(x => x.lat && x.lat.length > 0 && x.long && x.long.length > 0);

    if (matchesWithCoords.length == 0 && mapSettings.matchesWithCoords.length != 0) {
      setMapSettings(emptySettings);
      return;
    }

    let lat = 28.002;
    let lng = -82.57;

    // console.log('with coords', matchesWithCoords.length)
    let latmin: number = 0.;
    let latmax: number = 0.;
    let lngmin: number = 0.;
    let lngmax: number = 0.;
  
    let latsum = 0;
    let lngsum = 0;
    let npoints = 0;
    for (const facility of matchesWithCoords) {
      if (facility.lat && facility.long) {
        const latvalue = parseFloat(facility.lat);
        const lngvalue = parseFloat(facility.long);
  
        latsum += latvalue;
        lngsum += lngvalue;
        npoints++;
  
        if (latmin == 0. || latvalue < latmin) { latmin = latvalue;}
        if (latmax == 0. || latvalue > latmax) { latmax = latvalue;}
        if (lngmin == 0. || lngvalue < lngmin) { lngmin = lngvalue;}
        if (lngmax == 0. || lngvalue > lngmax) { lngmax = lngvalue;}
      }
    }
    const bounds = [[latmin, lngmax] as any, [latmax, lngmin] as any];
    // console.log('bounds', bounds);
    if (npoints > 0) {
      lat = latsum / npoints;
      lng = lngsum / npoints;
    }

    //console.log(`matchesWithCoords=${matchesWithCoords.length}. latmin=${latmin}, latmax=${latmax}, lngmin=${lngmin}, lngmax=${lngmax}, bounds=${bounds}`);

    if (latsum != mapSettings.latsum) {
      // console.log('map change', latsum, mapSettings.latsum);
      //const settings = {...mapSettings};
      //settings.matchesWithCoords = matchesWithCoords;
      //setMapSettings(settings);
      setMapSettings({matchesWithCoords, lat, lng, latsum, bounds});
    }

  }, [matches]);

  // Do not display if we are in stateview
  //if (stateview && stateview.length > 0) {
  //  return null;
  //}

  // Only show if enabled
  //if (!mapview) {
  //  return null;
  //}




  //console.log('center', lat, lng);
  //console.log('bounds', bounds);

  function ChangeView({center, zoom, bounds }: any) {
    const map = useMap();
    map.setView(center, zoom);
    //new LatLngBounds(bounds).pad(0.5);
    map.fitBounds(new LatLngBounds(bounds).pad(0.2))
    //map.fitBounds(bounds);
    return null;
  }

  if (!sessionid || sessionid.length == 0) {
    return (
      null
    );
  }

  if (true) {
    // console.log('Rendering map with ', mapSettings.bounds, 'points');
  return (
    <div>
    <section className="section-map">

    <div className="container-medium">
      <div className="padding-small">
        <div className="map-plus-pagenav-wrapper">
        <MapContainer style={{ maxHeight: '480px', maxWidth: '640px'}} center={{ lat: mapSettings.lat, lng: mapSettings.lng }} zoom={12}>
      <ChangeView center={{ lat: mapSettings.lat, lng: mapSettings.lng }} bounds={mapSettings.bounds} zoom={12} />
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright%22%3EOpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <>
      {mapSettings.matchesWithCoords.map((facility:IFacilityInterface) => (
        <Marker key={sequenceid++} 
          position={[parseFloat(facility.lat || '28'), parseFloat(facility.long || '-82')]}
        >
          <Popup>
            <Link to={'/details/' + facility.id}>{facility.name}</Link><br/>
            {facility.address}<br/>
            {facility.city}, {facility.state} {facility.zip}
          </Popup>
        </Marker>
      ))}
      </>

    </MapContainer>

        </div>
      </div>
    </div>
  </section>
</div>

  );
  }
};

export default Mapview;