// IntroText.tsx
// Intro text and warranty used on Welcome page (see IntroStateText for stateview)

export const IntroText = () => {
  return (
    <div className="header-text-wrapper">
    <p className="header-text header-text-first-paragraph header-text-first-paragraph-mobile">The federal hospital price transparency rule requires hospitals to publish their prices for all items and services online in a machine-readable standard charges file for all negotiated rates by payer and plan, including discounted cash prices.</p>
    <p className="header-text">
      <a href="https://www.patientrightsadvocate.org/" className="header-text-link">PatientRightsAdvocate.org</a>  aggregated publicly available price transparency data from 6,000 U.S. hospitals to empower all Americans with actual, upfront hospital prices. We are working on behalf of patients, employers, unions, and workers in the fight for systemwide healthcare price transparency to reduce healthcare costs through a functional, competitive market.
    </p>
    <p className="header-text">
      Click <a target="_blank" href="https://www.youtube.com/watch?v=vea8NVGc-zw&authuser=0">here</a> to watch a video on how to use the Hospital Price Files Finder.
    </p>    
    <p className="header-text" style={{fontSize: '70%'}}>
    Because of the scope and constantly changing nature of hospital prices, PRA makes no warranty, and expressly disclaims all warranties, as to the accuracy or completeness of the files and other information provided. Price and other information should always be verified directly with hospitals and service providers. All use of this site and database is governed by our <a href="https://www.patientrightsadvocate.org/termsofservice">Terms of Service</a>.
    </p>
  </div>

  );
};



