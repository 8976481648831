// IntroStateText.tsx
// Intro text and warranty used Stateview

export const IntroStateText = () => {
  return (
    <div className="header-text-wrapper">
    <p className="header-text" style={{fontSize: '70%'}}>
    Because of the scope and constantly changing nature of hospital prices, PRA makes no warranty, and expressly disclaims all warranties, as to the accuracy or completeness of the files and other information provided. Price and other information should always be verified directly with hospitals and service providers. All use of this site and database is governed by our Terms of Service.
    </p>
  </div>

  );
};
