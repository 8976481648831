// constant.service.ts
// Shared constants (such as stateview) between components


export default class ConstantService
{
  // Only run the stateview check once
  private static checkedView: boolean = false;
  // 'CO' restricts the client to a single state. listview and mapview are not used
  private static stateview:string|null = null; // 'CO'; // null; // 'CO'; 

  public static getStateView(): string|null {
    // Only run the stateview check once
    if (!this.checkedView) {
      const host = window.location.hostname.toUpperCase();
      const subdomain = host.split('.')[0];
      // console.log('host', host, subdomain);

      // Don't allow all states; just the ones we are allowing
      if (subdomain === 'CO' || subdomain === 'NY' || subdomain === 'WI' || subdomain === 'OH') {
        ConstantService.stateview = subdomain;
      }

      this.checkedView = true;
    }

    return ConstantService.stateview;
  }
}
