// Stateview.tsx
// Shows mapview and listview for one state.

import * as React from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import { LatLngBoundsLiteral, LatLngBounds } from 'leaflet';
import { Link } from 'react-router-dom';

import { IContextInterface, IFileInterface, IMatchInterface, IFacilityInterface } from '../../@types/context';

import { SearchContext } from '../context/SearchContext';
import { MatchContext } from '../context/MatchContext';

import Mapview from '../Mapview/Mapview';
import Facilities from '../facility/Facilities/Facilities';

const Stateview = () => {
  const { sessionid } = React.useContext(SearchContext) as IContextInterface;
  const {matches, error, stateview, mapview } = React.useContext(MatchContext) as IMatchInterface;
  //const [currentSettings, setCurrentSettings] = useState<FacilitySearchSettings>(emptySettings);

  // Do not display if we are in stateview
  if (stateview && stateview.length > 0) {
    return (
      <div>
        {// I'm reusing details-map-thumbnail-wrapper to turn off display for mobile
        }
        <div id="w-node-_31d4f197-fc05-af3b-883d-723a5b83e473-8d4be3ed" className="details-map-thumbnail-wrapper">
          <Mapview />
        </div>

        <Facilities />
      </div>
    )
  }

  return null;
};

export default Stateview;