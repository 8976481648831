// ClickableUSAMap.tsx

import React, { useState } from 'react';
import {MapCustomizations, USAMap} from './USAMap';

// Keep track of the last state hover
let laststate = '';

type Props = {
  onclick: any;
};

const ClickableUSAMap: React.FC<Props> = ({ onclick }) => {
  const [ customize, setCustomize ] = useState<MapCustomizations>({});


  const mapClickHandler = (state: string) => {
    //console.log(`clicked ${state}`);
    laststate = '';
    onclick(state);
    // setCustomize({ ...customize, [state]: { fill: '#00ff00'}});
  }

  const mapFlyoverHandler = (state: string) => {
    //console.log(`mouse entered ${state}`);

    let newstate = {...customize};
    if (laststate.length > 0) {
      newstate = {...newstate, [laststate]: { fill: '#babbd4'}};
    }
    newstate = {...newstate, [state]: { fill: '#f3f2ff'}};
    setCustomize(newstate);
    laststate = state;
  }


  const mapLeaveHandler = (state: string) => {
    //console.log(`mouse leave ${state}`);
    //setCustomize({ ...customize, [state]: { fill: '#babbd4'}});
  }

  const mapClickEventHandler = (event: React.MouseEvent<SVGPathElement>) => {
    // if some typings whiz knows a better way, lemme know!
    //const dataset = (event.target as SVGPathElement).dataset as MapMouseEventDataset;
    //console.log(dataset);
    //console.log('mapClickEventHandler', event);
  }

  return (

          <div>
            <USAMap
              onClick={mapClickHandler}
              onClickEvent={mapClickEventHandler}
              onMouseOver={mapFlyoverHandler}
              onMouseLeave={mapLeaveHandler}
              customize={customize}
              width={800}
              />
          </div>

  );
}

export default ClickableUSAMap;