// MapviewOnly.tsx
// Wrapper around Mapview.tsx, and used when all states are enabled (ie. mapview)

import * as React from 'react';
import { IContextInterface, IMatchInterface, IFacilityInterface } from '../../@types/context';
import { SearchContext } from '../context/SearchContext';
import { MatchContext } from '../context/MatchContext';
import Mapview from '../Mapview/Mapview';
// import '../../../styles.css'

const MapviewOnly = () => {
  const { sessionid } = React.useContext(SearchContext) as IContextInterface;
  const {matches, error, stateview, mapview } = React.useContext(MatchContext) as IMatchInterface;
  //const [currentSettings, setCurrentSettings] = useState<FacilitySearchSettings>(emptySettings);

  // Do not display if we are in stateview
  if (stateview && stateview.length > 0) {
    return null;
  }

  if (!mapview) {
    return null;
  }

  return (
    <Mapview />
  )

};

export default MapviewOnly;