// mapdetails.tsx
import * as React from 'react';
import { useLoaderData } from 'react-router-dom';

import { IContextInterface, IFileInterface, IMatchInterface, IFacilityInterface } from '../@types/context';
import { SearchContext } from '../components/context/SearchContext';
import { MatchContext } from '../components/context/MatchContext';

import MapDetails from '../components/facility/MapDetails/MapDetails';
import { Header } from '../components/branding/Header';
import { Footer } from '../components/branding/Footer';

export async function loader({params}: any) {
  const facilityid = params.facility;
  // console.log('facilityid', facilityid);

  return { facilityid };
}

export default function Map() {
  const params = useLoaderData() as any;
  const facilityid = params.facilityid;
  // console.log('response', facilityid);

  const { stateview } = React.useContext(MatchContext) as IMatchInterface;
  
  return (
    <div>
      { !stateview &&
        <Header/>
      }
    <main className="main-wrapper">
      <div>
        <MapDetails key={facilityid} facilityid={facilityid}/>
      </div>
    </main>

    </div>
  );
}