// root.tsx (Used to be App.tsx)

import React, {useEffect} from 'react';
import { Routes, Route, Outlet, Link } from "react-router-dom";
import '../styles.css';

import FacilitySearch from '../components/facility/FacilitySearch/FacilitySearch';
import FacilitiesOnly from '../components/facility/FacilitiesOnly/FacilitiesOnly';
import MapviewOnly from '../components/MapviewOnly/MapviewOnly';
import Session from '../components/user/Session/Session';
import ServerService from '../services/server.service';

import { IContextInterface, IFileInterface, IMatchInterface, IFacilityInterface } from '../@types/context';
import { SearchContext } from '../components/context/SearchContext';
import { MatchContext } from '../components/context/MatchContext';

import { Header } from '../components/branding/Header';
import { Welcome } from '../components/branding/Welcome';
import { Footer } from '../components/branding/Footer';
import Stateview from '../components/Stateview/Stateview';

import ConstantService from '../services/constant.service';
// const constantService = new ConstantService();

export default function Root() {

  const { sessionid } = React.useContext(SearchContext) as IContextInterface;
  const { stateview, saveView } = React.useContext(MatchContext) as IMatchInterface;

  useEffect(() => {
    const findServer = async () => {
      const serverService = new ServerService();
      const resp = await serverService.getResponse('check');
      // console.log('Root check', resp);
    };

    // Ping the server to get a sessionid and requestid, but only if we do not have one
    if (sessionid && sessionid.length > 0) {
      // Do nothing
    }
    else {
      findServer();
    }
  }, []);

  return (
    <div>
      <div className="page-wrapper">
        <div className="global-styles w-embed">
          <link href="/css/globalstyles.css" rel="stylesheet" type="text/css"/>
        </div>

        { !stateview &&
          <Header/>
        }
        <main className="main-wrapper">
          <div>
            <Session/>
            <Welcome/>
            <FacilitySearch tableid="123" />
            <Stateview />
            <FacilitiesOnly />
            <MapviewOnly />
          </div>
        </main>
      </div>

    </div>
  );
}