// FacilitiesOnly.tsx
// Wrapper around Facilities.tsx, and used when all states are enabled (ie. listview)

import * as React from 'react';
import { IContextInterface, IMatchInterface, IFacilityInterface } from '../../../@types/context';
import { SearchContext } from '../../context/SearchContext';
import { MatchContext } from '../../context/MatchContext';
import Facility from '../Facility/Facility';
import Facilities from '../Facilities/Facilities';
// import '../../../styles.css'

const FacilitiesOnly = () => {
  const { sessionid } = React.useContext(SearchContext) as IContextInterface;
  const {matches, error, stateview, listview } = React.useContext(MatchContext) as IMatchInterface;
  //const [currentSettings, setCurrentSettings] = useState<FacilitySearchSettings>(emptySettings);

  // Do not display if we are in stateview
  if (stateview && stateview.length > 0) {
    return null;
  }

  if (!listview) {
    return null;
  }

  return (
    <Facilities />
  )

};

export default FacilitiesOnly;