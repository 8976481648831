// MatchContext.js
import React, { createContext, useState, FC, ReactNode } from 'react';

import { IFacilityInterface, IMatchInterface } from '../../@types/context';
import ConstantService from '../../services/constant.service';

function delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}

// nop versions of setting the context
function default0(session: string) {};
function default1(search?: string, searchState?: string) {};
function default4(matchSearch: string, matchState: string, matches: IFacilityInterface[], error?: string) {};
function default3(stateview: string | undefined, listview: boolean, mapview: boolean, welcome: boolean) {};


const defaultMatchContext = {
  matches: [],
  matchSearch: '',
  matchState: '',
  error: '',
  stateview: undefined,
  listview: false,
  mapview: false,
  welcome: true,
  welcomeState: '',
  saveWelcomeState: default0,
  saveMatches: default4,
  saveView: default3,
};

interface Props {
  children: ReactNode;
}

export const MatchContext = createContext<IMatchInterface>(defaultMatchContext);

//const AppProvider: React.FC<React.ReactNode> = ({ children: {children: ReactNode} }) => {
const AppMatchProvider: React.FC<Props> = ({ children }: Props) => {
  const [context, setContext] = React.useState<IMatchInterface>();

  //React.useEffect(() => {
  //  //console.log('useEffect context', context);
  //})

  const saveWelcomeState = async (welcomeState: string) => {
    //console.log('saveWelcomeState', welcomeState);
    const newcontext: IMatchInterface = {
      stateview: context ? context.stateview : undefined,
      listview: context ? context.listview : false,
      mapview: context ? context.mapview : false,
      welcome: context ? context.welcome : true,
      welcomeState,
      matchSearch: context ? context.matchSearch : '',
      matchState: context ? context.matchState : '',
      matches: context ? context.matches : [],
      error: context ? context.error : '',
      saveWelcomeState: context ? context.saveWelcomeState : default0,
      saveMatches: context ? context.saveMatches : default4,
      saveView: context ? context.saveView : default3,
    };
    
    setContext(newcontext);
  }

  const saveMatches = (matchSearch: string, matchState: string, matches: IFacilityInterface[], error?: string) => {
    //console.log(`saveMatches: ${context?.searchState}`, newsearch, newsearchState);
    const newcontext: IMatchInterface = {
      stateview: context ? context.stateview : undefined,
      listview: context ? context.listview : false,
      mapview: context ? context.mapview : false,
      welcome: context ? context.welcome : true,
      welcomeState: context ? context.welcomeState : '',
      matches,
      matchSearch,
      matchState,
      error: error ? error : '',
      saveWelcomeState: context ? context.saveWelcomeState : default0,
      saveMatches: context ? context.saveMatches : default4,
      saveView: context ? context.saveView : default3,
    };
    setContext(newcontext);
  }

  const saveView = (stateview: string | undefined, listview: boolean, mapview: boolean, welcome: boolean) => {
    //console.log('saveView', search, searchState);
    const newcontext: IMatchInterface = {
      stateview,
      listview,
      mapview,
      welcome,
      welcomeState,
      matchSearch: context ? context.matchSearch : '',
      matchState: context ? context.matchState : '',
      matches: context ? context.matches : [],
      error: context ? context.error : '',
      saveWelcomeState: context ? context.saveWelcomeState : default0,
      saveMatches: context ? context.saveMatches : default4,
      saveView: context ? context.saveView : default3,
    };
    setContext(newcontext);    
  }

  const matchSearch = context ? context.matchSearch : '';
  const matchState = context ? context.matchState : '';
  const matches = context ? context.matches : [];
  const error = context ? context.error : '';
  const sv = ConstantService.getStateView();
  const stateview = sv ? sv : (context ? context.stateview : undefined);
  const listview = sv ? true : (context ? context.listview : false);
  const mapview = sv ? true : (context ? context.mapview : false);
  const welcome = sv ? false : (context ? context.welcome : true);
  const welcomeState = context ? context.welcomeState : '';

  return <MatchContext.Provider value={{ stateview, listview, mapview, welcome, welcomeState, matchSearch, matchState, matches, error, saveWelcomeState, saveMatches, saveView }}>{children}</MatchContext.Provider>;
};  
  
export default AppMatchProvider;
