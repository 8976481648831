// MapDetails.tsx

import * as React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Link, useNavigate } from 'react-router-dom';

import { IContextInterface, IFileInterface, IMatchInterface, IFacilityInterface } from '../../../@types/context';
import { SearchContext } from '../../context/SearchContext';
import { MatchContext } from '../../context/MatchContext';

import Facility from '../Facility/Facility';

import MissingPage from '../../../routes/missingpage';
import '../../../styles.css'

import AnalyticsService from '../../../services/analytics.service';
const analyticsService = new AnalyticsService();

type Props = {
  facilityid: string;
};

const MapDetails: React.FC<Props> = ({ facilityid }) => {
  const navigate = useNavigate();

  const { matches } = React.useContext(MatchContext) as IMatchInterface;
  //console.log('matches', facilityid, matches);

  const facility: IFacilityInterface | undefined = matches.find(x => x.id == facilityid);

  const marker = React.useRef(null);
  
  React.useEffect(() => {
    if (!facility) {
      navigate('/');
    }
    else {
      // Record this event
      const title = `map for ${facility.name}, ${facility.city}, ${facility.state}`;
      analyticsService.pageView(title, window.location.href);
      //analyticsService.pageTracking('mapdetails', 'mapdetails', {id: facilityid, name: facility.name, city: facility.city, state: facility.state});
    }
  });

  if (!facility) {
    return null;
  }




  return (
    <div>
    <main className="main-wrapper">
      <section className="section-details-hospital-info">
        <div className="container-small center-details-page">
          <div className="padding-small">
            <div className="map-plus-pagenav-wrapper">
              <div className="page-back-navigation-wrapper">
                <a href="#" onClick={() => navigate(-1)} className="back-to-details-link-block w-inline-block"><img src="/images/ICON_back.svg" loading="lazy" alt="back button
" className="icon_back"/>
                  <div className="hospital-name-map-header-nav">{facility.name}</div>
                </a>
              </div>
              <div className="map-detail-image-wrapper">
                <div style={{width:500, height: 274}}>
                  <MapContainer whenReady={() => {
                    setTimeout(() => { (marker as any).current.openPopup() }, 250);
                      //marker?.current?.openPopup();}, 200);
                  }} style={{ maxHeight: '274px', maxWidth: '500px'}} center={[parseFloat(facility?.lat || '28'), parseFloat(facility?.long || '-82')]} zoom={14}>
                    <TileLayer
                      attribution='&copy; <a href="http://osm.org/copyright%22%3EOpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                    <Marker ref={marker} position={[parseFloat(facility?.lat || '28'), parseFloat(facility?.long || '-82')]}>
                      <Popup>
            <Link to={'/details/' + facility.id}>{facility.name}</Link><br/>
            {facility.address}<br/>
            {facility.city}, {facility.state} {facility.zip}
                      </Popup>
                    </Marker>
                  </MapContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    </div>
  );
};


export default MapDetails;
