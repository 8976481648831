// Facilities.tsx

import * as React from 'react';
import { IContextInterface, IMatchInterface, IFacilityInterface } from '../../../@types/context';
import { SearchContext } from '../../context/SearchContext';
import { MatchContext } from '../../context/MatchContext';
import Facility from '../Facility/Facility';
// import '../../../styles.css'

const Facilities = () => {
  const { sessionid } = React.useContext(SearchContext) as IContextInterface;
  const {matches, error, stateview, listview } = React.useContext(MatchContext) as IMatchInterface;
  //const [currentSettings, setCurrentSettings] = useState<FacilitySearchSettings>(emptySettings);

  // Do not display if we are in stateview
  //if (stateview && stateview.length > 0) {
  //  return null;
  //}

  //if (!listview) {
  //  return null;
  //}

  if (!sessionid || sessionid.length == 0) {
    return (
      null
    );
  }

  // Render error
  let errorstring = null;
  if (error && error.length > 0) {
    if (error === 'resultsexceeded') {
      errorstring = 'Too many results. Narrow your search.';
    }
    else if (error === 'smallsearch') {
      errorstring = 'Enter at least 3 characters to search.'
    }
  }

  return (
    <section className="section-search-results">
    <div className="container-medium">
      <div className="padding-small">
        <div className="search-results-wrapper">
          <div className="section-divider-line"></div>
          <div className="number-of-results-wrapper">
            <div className="number-of-results">{matches.length}</div>
            <div className="results-label">results</div>
            { errorstring &&
              <span style={{marginLeft: 0}} className="results-label">.&nbsp;{errorstring}</span>
            }
          </div>
    <>
      {matches.map((facility: IFacilityInterface) => (
        <Facility key={facility.id} facility={facility} />
      ))}
    </>

        </div>
      </div>
    </div>
    </section>

  );
};

export default Facilities;