// Welcome.tsx
import React, { useState } from 'react';
import { IContextInterface, IMatchInterface, IFacilityInterface } from '../../@types/context';
import { SearchContext } from '../context/SearchContext';
import { MatchContext } from '../context/MatchContext';
import { IntroText } from './IntroText';

import ClickableUSAMap from '../USAState/ClickableUSAMap';

function delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}

export const Welcome = () => {
  const { sessionid, saveSearch } = React.useContext(SearchContext) as IContextInterface;

  const { stateview, welcome, welcomeState, saveWelcomeState, saveView } = React.useContext(MatchContext) as IMatchInterface;

  const onclickState = async function(state: string) {
    saveView(stateview, true, false, false);
    saveSearch('', state);

    // console.log('onclick', welcomeState, state)
  }

  // stateview does not have a welcome screen. Use this to turn off the footer (which is found in index.html)
  if (stateview && stateview.length > 0) {
    const elements = document.getElementsByClassName('section-footer');
    if (elements.length > 0) {  
      const element = elements[0] as HTMLElement;
      if (element.style.display !== 'none') {
        // console.log(element);
        element.style.display = 'none';
      }
    }

    return (
      null
    );
  }

  if (!sessionid || !welcome) {
    return (
      null
    );
  }

  return (
    <div>
    <section className="section-title-publicly-availaible-ptf">
    <div className="container-medium">
      <div className="padding-small heading-title-top-padding">
        <div className="page-title-wrapper">
          <h2 className="heading heading-style-h2 mobile-header-title">Hospital Price Files Finder</h2>
        </div>
      </div>
    </div>
  </section>
  <section className="section-map-selector-paragraphs">
    <div className="container-medium">
      <div className="padding-small moble-header-text">
        <div className="header-text-map-wrapper">
          <IntroText />
          <div className="header-map-wrapper">
            <div className="padding-small">
              <div className="header-map-title-embed">
                <h4 className="map-title">
                  Select a State
                </h4>
                <div className="html-embed w-embed w-iframe w-script">
                  <ClickableUSAMap onclick={onclickState}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="mobile-button-to-search">
    <div className="container-small">
      <div className="padding-small">
        <div className="mobile-button-to-files-wrapper">
          <a onClick={() => onclickState('')} className="button-moble-go-to-search w-button">Start Search</a>
        </div>
      </div>
    </div>
  </section>
  </div>
  );

  return (

    <div className="container-medium">
      <div className="padding-small">
        <div className="search-results-wrapper">
          <div className="welcometitle">
            <b>Hospital Price Files Finder</b>
          </div>
          <div className="welcometext">
          PatientRightsAdvocate.org aggregated publicly available price transparency 
          data from 6,000 U.S. hospitals to empower all Americans with actual, upfront hospital prices.
          We are working on behalf of patients, employers, unions, and workers in the fight for systemwide
          healthcare price transparency to reduce healthcare costs through a functional, competitive market.        
          </div>
          <ClickableUSAMap onclick={onclickState}/>
        </div>
      </div>
    </div>

  );
};



